import { useGalleryContract } from "./useContract";
import useTransaction from "./useTransaction";

type UseCollectionActionProps = {
  contract: string;
};

const useCollectionAction = ({ contract }: UseCollectionActionProps) => {
  const { callTransaction } = useTransaction();
  const galleryContract = useGalleryContract(contract);

  const ownerOf = async (tokenId: number) => {
    return await callTransaction(galleryContract, "ownerOf", [tokenId]);
  };

  const tokenURI = async (tokenId: number) => {
    return await callTransaction(galleryContract, "tokenURI", [tokenId]);
  };

  return {
    ownerOf,
    tokenURI,
  };
};

export default useCollectionAction;
