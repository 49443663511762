import React from "react";
import { Button } from "@mui/material";

type MainButtonProps = {
  disabled?: boolean;
  title: string;
  handleAction: () => void;
};

const MainButton = ({
  title,
  handleAction,
  disabled,
  ...props
}: MainButtonProps) => {
  return (
    <Button
      variant="contained"
      sx={{ height: "48px", borderRadius: "24px" }}
      onClick={handleAction}
      fullWidth
      disabled={disabled}
      {...props}
    >
      {title}
    </Button>
  );
};

export default MainButton;
