import React, { useEffect, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import FixedFooter from "components/FixedFooter";
import MainButton from "components/MainButton";
import SecoundaryButton from "components/SecoundaryButton";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import BridgeInstruction from "./bridgeInstruction";
import { styled } from "@mui/system";
import { ReactComponent as EthIcon } from "assets/icons/eth_icon.svg";
import { useNavigate } from "react-router-dom";
import useNavbarHeight from "components/useNavbarHeight";
import useNftBridge from "smartcontract/hooks/useNftBridge";
import { useWeb3React } from "@web3-react/core";
import LoadingPrompt from "components/LoadingPrompt";

const BoxMainInfo = styled(Box)(({ theme }) => ({
  padding: "16px",
  borderRadius: "16px",
  backgroundColor: theme.palette.grey.accent4,
}));

const BridgeDetail = () => {
  const { token, collection, isAutoFill } = useLocation().state;
  const navigate = useNavigate();
  const navbarHeight = useNavbarHeight();
  const { active, account } = useWeb3React();
  const { withdrawNftBridge } = useNftBridge({
    collectionAddress: process.env.REACT_APP_DST_ERC_721_HANDLER || "",
  });
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!active) handleOnClickCancel();
  }, [active]);

  const details = useMemo(
    () => [
      {
        id: "nft-name",
        title: "NFT name",
        value: token.metadata?.name,
      },
      {
        id: "nft-collection-name",
        title: "Collection name",
        value: collection.name,
      },
      {
        id: "nft-id",
        title: "Token ID",
        value: "#" + token.tokenID.toString().padStart(3, "0"),
      },
      {
        id: "nft-source",
        title: "Source",
        value: "ABC Chain",
      },
    ],
    [token, collection]
  );

  const handleOnClickCancel = () =>
    isAutoFill
      ? navigate(`/${collection.address}/${token.tokenID}`)
      : navigate("/");
  const handleConfirmTransaction = async () => {
    try {
      setLoading(true);
      const resultTransaction = await withdrawNftBridge(
        collection.address,
        token.tokenID
      );
      setLoading(false);
      if (
        resultTransaction?.transactionHash &&
        resultTransaction?.status === 1
      ) {
        navigate("/success-bridge-nft", {
          state: {
            collection,
            token,
          },
        });
      }
    } catch (error) {
      setLoading(false);
      handleOnClickCancel();
    }
  };

  return (
    <>
      <Helmet>
        <title>You about to send</title>
      </Helmet>
      <Box maxWidth="552px" m="auto" bgcolor="#ffffff">
        <Box
          sx={{
            overflowY: "auto",
            height: `calc(100vh - ${navbarHeight}px - 165px)`,
            gap: "16px",
          }}
          p="16px"
          display="flex"
          flexDirection="column"
        >
          <Box
            data-testid="img_nft-image"
            component="img"
            src={token?.metadata?.image}
            height="80px"
            width="80px"
            borderRadius="16px"
            m="auto"
          />
          {details?.map((item) => {
            return (
              <Box key={item.id} display="flex" justifyContent="space-between">
                <Typography
                  data-testid={`text_title-${item.id}`}
                  variant="body2"
                  sx={{ color: "grey.accent2" }}
                >
                  {item.title}
                </Typography>
                <Typography
                  data-testid={`text_detail-${item.id}`}
                  variant="body2"
                >
                  {item.value}
                </Typography>
              </Box>
            );
          })}
          <Typography variant="subtitle1">To</Typography>
          <Typography variant="subtitle1">Destination chain</Typography>
          <BoxMainInfo display="flex" alignItems="center" sx={{ gap: "8px" }}>
            <EthIcon />
            <Typography data-testid="text_chain-name" variant="body2">
              Ethereum
            </Typography>
          </BoxMainInfo>
          <Typography variant="subtitle1">Wallet address</Typography>
          <BoxMainInfo sx={{ wordWrap: "break-word" }}>
            <Typography data-testid="text_wallet-address" variant="body2">
              {active ? account : "-"}
            </Typography>
          </BoxMainInfo>
          <BridgeInstruction />
        </Box>
        <FixedFooter>
          <MainButton
            data-testid="btn_bridge"
            title="BRIDGE"
            handleAction={handleConfirmTransaction}
          />
          <SecoundaryButton
            data-testid="btn_cancel"
            title="CANCEL"
            handleAction={handleOnClickCancel}
          />
        </FixedFooter>
      </Box>
      <LoadingPrompt title="Bridging..." isVisible={loading} />
    </>
  );
};

export default BridgeDetail;
