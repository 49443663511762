import React from "react";
import { Button } from "@mui/material";

type SecoundaryButtonProps = {
  title: string;
  handleAction: () => void;
};

const SecoundaryButton = ({
  title,
  handleAction,
  ...props
}: SecoundaryButtonProps) => {
  return (
    <Button
      variant="text"
      sx={{ height: "48px", borderRadius: "24px" }}
      onClick={handleAction}
      {...props}
    >
      {title}
    </Button>
  );
};

export default SecoundaryButton;
