import React, { useEffect, useState } from "react";
import { Typography, Box } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import FixedFooter from "components/FixedFooter";
import MainButton from "components/MainButton";
import useNavbarHeight from "components/useNavbarHeight";
import ConnectWalletModal from "components/ConnectWalletModal";
import { ABCTextField, ABCSelect, ABCMenuItem } from "components/InputField";
import { useWeb3React } from "@web3-react/core";
import LoadingPrompt from "components/LoadingPrompt";
import Prompt from "components/Prompt";
import useCollectionAction from "smartcontract/hooks/useCollectionAction";
import { getNftInfo } from "api/ipfsService";

type PromptState = {
  isVisible: boolean;
  title: string;
  subTitle: string;
  buttonText: string;
};

type CollectionSelected = {
  label: string;
  value: string;
};

const Home = () => {
  const navigate = useNavigate();
  const { collection, token } = useParams();
  const navbarHeight = useNavbarHeight();
  const [openWallet, setOpenWallet] = useState(false);
  const [tokenIdSelect, setTokenIdSelect] = useState("");
  const [collectionSelect, setCollectionSelect] = useState("");
  const [collectionList, setCollectionList] = useState<CollectionSelected[]>(
    []
  );
  const [loading, setLoading] = useState<boolean>(false);
  const { active, account } = useWeb3React();
  const [promptError, setPromptError] = useState<PromptState>({
    isVisible: false,
    title: "",
    subTitle: "",
    buttonText: "",
  });
  const { ownerOf, tokenURI } = useCollectionAction({
    contract: collectionSelect,
  });

  const getCollectionList = () => {
    const tempList = JSON.parse(
      process.env.REACT_APP_COLLECTIONS_ADDRESS as string
    ).map((collection: string, index: number) => ({
      label: JSON.parse(process.env.REACT_APP_ฺCOLLECTIONS_NAME as string)[
        index
      ],
      value: collection,
    }));
    if (tempList.length > 0) setCollectionSelect(tempList[0].value);
    setCollectionList(tempList);
  };

  useEffect(() => {
    getCollectionList();
  }, []);

  useEffect(() => {
    if (
      collection &&
      JSON.parse(process.env.REACT_APP_COLLECTIONS_ADDRESS as string)?.find(
        (c) => c === collection
      )
    )
      setCollectionSelect(collection);
    if (token && !(!regEx.test(token) || token === "0"))
      setTokenIdSelect(token);
  }, [collection, token]);

  const regEx = new RegExp("^[0-9]*$");
  const handleTokenIdChange = (e: any) => {
    const { value } = e.target;
    if (!regEx.test(value) || value === "0") {
      return;
    }
    setTokenIdSelect(value);
  };

  const handleProcessBridge = async () => {
    setLoading(true);
    try {
      const checkOwner = await ownerOf(parseInt(tokenIdSelect));
      if (checkOwner !== process.env.REACT_APP_DST_ERC_721_HANDLER)
        throw new Error("not found");
      const tokenUriresponse = await tokenURI(parseInt(tokenIdSelect));
      const nftInfo = await getNftInfo(tokenUriresponse);
      setLoading(false);
      navigate("/bridge-detail-nft", {
        state: {
          collection: {
            name: collectionList?.find(
              (collection) => collection.value === collectionSelect
            )?.label,
            address: collectionSelect,
          },
          token: {
            tokenID: tokenIdSelect,
            metadata: nftInfo,
          },
          isAutoFill: collection !== undefined,
        },
      });
    } catch (error) {
      setLoading(false);
      setPromptError({
        isVisible: true,
        title: "Oops, there was an issue",
        subTitle: "Please try again",
        buttonText: "OK",
      });
    }
  };

  const handleCollectionChange = (event: any) => {
    setCollectionSelect(event.target.value);
  };

  return (
    <>
      <Helmet>
        <title>Bridge</title>
      </Helmet>
      <Box maxWidth="552px" m="auto">
        <Box
          sx={{
            overflowY: "auto",
            height: `calc(100vh - ${navbarHeight}px - 160px)`,
            gap: "16px",
          }}
          p="16px"
          display="flex"
          flexDirection="column"
        >
          <Typography variant="subtitle1">Collection Names</Typography>
          <ABCSelect
            data-testid="navbar-select"
            value={collectionSelect}
            onChange={handleCollectionChange}
            variant="standard"
            disableUnderline
            disabled={collection !== undefined}
          >
            {collectionList.map((item) => (
              <ABCMenuItem
                key={`collection-select-${item.value}`}
                value={item.value}
              >
                {item.label}
              </ABCMenuItem>
            ))}
          </ABCSelect>
          <Typography variant="subtitle1">Token ID</Typography>
          <ABCTextField
            inputProps={{ "data-testid": "input_token-id" }}
            value={tokenIdSelect}
            onChange={handleTokenIdChange}
            disabled={token !== undefined}
          />
          <Box mt="32px">
            <Typography variant="subtitle1" sx={{ marginBottom: "16px" }}>
              Destination Wallet Address
            </Typography>
            <MainButton
              data-testid="btn_connect"
              title={
                active
                  ? `${account?.substring(0, 5)}...${account?.substring(
                      account?.length - 5
                    )}`
                  : "CONNECT WALLET"
              }
              handleAction={() => (active ? null : setOpenWallet(true))}
            />
          </Box>
        </Box>
        <FixedFooter>
          <MainButton
            disabled={!active || !tokenIdSelect}
            data-testid="btn_next"
            title="NEXT"
            handleAction={handleProcessBridge}
          />
          <Box height="16px" />
        </FixedFooter>
      </Box>
      <ConnectWalletModal
        open={openWallet}
        handleClose={() => setOpenWallet(false)}
      />
      <LoadingPrompt title="Loading..." isVisible={loading} />
      <Prompt
        isVisible={promptError.isVisible}
        onClose={() =>
          setPromptError((prev) => ({ ...prev, isVisible: false }))
        }
        title={promptError.title}
        subTitle={promptError.subTitle}
        buttonText={promptError.buttonText}
      />
    </>
  );
};

export default Home;
