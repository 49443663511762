import { InjectedConnector } from "@web3-react/injected-connector";
import { Web3Provider } from "@ethersproject/providers";

export const injected = new InjectedConnector({
  supportedChainIds: [parseInt(process.env.REACT_APP_CHAIN_ID || "1", 10)],
});

export const getLibrary = (provider) => {
  return new Web3Provider(provider);
};
