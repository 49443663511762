const useCallTransaction = () => {
  const sendTransaction = async (
    contract: any,
    method: string,
    args: any,
    overrides: any = null,
    isWaiting = true
  ) => {
    try {
      const raw = await contract[method](...args, { ...overrides });
      if (raw.hash) {
        if (isWaiting) return await raw.wait();
        return raw;
      }
      return null;
    } catch (error: any) {
      return error.message;
    }
  };

  const callTransaction = async (
    contract: any,
    method: string,
    args: any,
    overrides: any = null
  ) => {
    try {
      return await contract[method](...args, { ...overrides });
    } catch (error: any) {
      return error.message;
    }
  };

  return { sendTransaction, callTransaction };
};

export default useCallTransaction;
