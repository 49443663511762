import React from "react";
import { Box, Paper } from "@mui/material";

const FixedFooter = ({ children }: { children: React.ReactNode }) => {
  return (
    <Paper
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
      }}
      elevation={0}
    >
      <Box
        p="16px"
        m="auto"
        display="flex"
        flexDirection="column"
        sx={{
          padding: "16px",
          gap: "16px",
          width: { xs: "auto", sm: "360px" },
        }}
      >
        {children}
      </Box>
    </Paper>
  );
};

export default FixedFooter;
