import { Box, Typography, Modal, CircularProgress } from "@mui/material";
import React from "react";
import { styled } from "@mui/system";
import LoadingIcon from "assets/icons/loading.svg";

const LoadingAnimationBox = styled(Box)`
  animation-duration: 1.4s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: animation-61bdi0;
`;

type LoadingPromptProps = {
  title: string;
  isVisible: boolean;
};

const LoadingPrompt = ({ title, isVisible }: LoadingPromptProps) => {
  return (
    <Modal
      data-testid="modal_loding-prompt"
      open={isVisible}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          bgcolor: "background.paper",
          width: "100%",
          maxWidth: "340px",
          minHeight: "110px",
          m: "16px",
          justifyContent: "center",
          borderRadius: "16px",
          p: "24px",
          overflowY: "auto",
          maxHeight: "100%",
          alignItems: "center",
          gap: "24px",
        }}
      >
        <CircularProgress sx={{ display: "none" }} />
        <LoadingAnimationBox
          sx={{
            width: "48px",
            height: "48px",
          }}
        >
          <Box
            id="img_logoPopup"
            component="img"
            src={LoadingIcon}
            sx={{
              width: "48px",
              height: "48px",
            }}
          />
        </LoadingAnimationBox>
        <Typography id="lbl_titlePopup" variant="body1">
          {title}
        </Typography>
      </Box>
    </Modal>
  );
};

export default LoadingPrompt;
