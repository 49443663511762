import { useNftContract } from "./useContract";
import useTransaction from "./useTransaction";

type UseERC721HandlerActionProps = {
  contract: string;
};

const useERC721HandlerAction = ({ contract }: UseERC721HandlerActionProps) => {
  const { sendTransaction } = useTransaction();
  const nftContract = useNftContract(contract);

  const userWithdraw = async (
    tokenAddress: string,
    recipient: string,
    tokenId: number
  ) => {
    return await sendTransaction(nftContract, "userWithdraw", [
      tokenAddress,
      recipient,
      tokenId,
    ]);
  };

  return {
    userWithdraw,
  };
};

export default useERC721HandlerAction;
