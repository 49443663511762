import { useWeb3React } from "@web3-react/core";
import useERC721HandlerAction from "./useERC721HandlerAction";

type UseNftBridgeProps = {
  collectionAddress: string;
};

function useNftBridge({ collectionAddress }: UseNftBridgeProps) {
  const { userWithdraw } = useERC721HandlerAction({
    contract: collectionAddress,
  });
  const { account } = useWeb3React();

  const withdrawNftBridge = async (tokenAddress: string, tokenId: number) => {
    const result = await userWithdraw(tokenAddress, account || "", tokenId);
    return result;
  };

  return { withdrawNftBridge };
}

export default useNftBridge;
