import React, { useEffect, useState } from "react";
import { AppBar, IconButton, Toolbar, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ReactComponent as NavbarABCLogo } from "assets/icons/navbar_abc_logo.svg";

const NavBar = () => {
  const navigate = useNavigate();
  const handleTitleClick = () => {
    navigate("/");
  };
  const [title, setTitle] = useState("");
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (document.title !== title) {
        setTitle(document.title);
      }
    }, 50);
    return () => {
      clearInterval(intervalId);
    };
  }, [title]);

  return (
    <AppBar
      data-testid="navbar"
      position="sticky"
      sx={{
        gridArea: "navbar",
        backgroundColor: "background.paper",
        boxShadow: "none",
        borderBottom: "1px solid ",
        borderBottomColor: "grey.accent4",
      }}
    >
      <Toolbar
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <IconButton
            data-testid="navbar-home-btn"
            size="large"
            sx={{
              justifyContent: "flex-start",
              padding: 0,
              margin: 0,
            }}
            onClick={handleTitleClick}
          >
            <NavbarABCLogo />
          </IconButton>
        </Box>

        <Typography
          data-testid="title-txt"
          variant="h6"
          color="text.primary"
          sx={{
            textAlign: "center",
          }}
        >
          {title}
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
