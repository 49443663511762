import React, { useEffect } from "react";
import { Box, Typography, Divider } from "@mui/material";
import FixedFooter from "components/FixedFooter";
import MainButton from "components/MainButton";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { styled } from "@mui/system";
import { ReactComponent as EthIcon } from "assets/icons/eth_icon.svg";
import { useNavigate } from "react-router-dom";
import useNavbarHeight from "components/useNavbarHeight";
import { useWeb3React } from "@web3-react/core";
import SouthIcon from "@mui/icons-material/South";

const ReviewCard = styled(Box)`
  display: flex;
  flex-direction: column;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
  border-radius: 16px;
  padding: 16px;
  gap: 16px;
  margin: 16px;
`;

const DetailDivinder = styled(Divider)`
  width: 90%;
  border-color: #e1e1e1;
  margin: auto;
`;

const IconBox = styled(Box)`
  height: 40px;
  min-width: 40px;
  border-radius: 100%;
  border: 1px solid #e1e1e1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
`;

const BridgeSuccess = () => {
  const { token } = useLocation().state;
  const navigate = useNavigate();
  const navbarHeight = useNavbarHeight();
  const { account, active } = useWeb3React();

  useEffect(() => {
    if (!active) handleOnClickOk();
  }, [active]);

  const handleOnClickOk = () => navigate("/");

  return (
    <>
      <Helmet>
        <title>Congratuation!</title>
      </Helmet>
      <Box maxWidth="552px" m="auto" bgcolor="#ffffff">
        <Box
          sx={{
            overflowY: "auto",
            height: `calc(100vh - ${navbarHeight}px - 140px)`,
            gap: "16px",
          }}
          p="16px"
          display="flex"
          flexDirection="column"
        >
          <ReviewCard>
            <Typography variant="subtitle1">You send</Typography>
            <Box display="flex" sx={{ gap: "8px" }}>
              <Box
                data-testid="img_nft-image"
                component="img"
                src={token?.metadata?.image}
                height="40px"
                width="40px"
                borderRadius="100%"
              />
              <Box display="flex" flexDirection="column" sx={{ gap: "8px" }}>
                <Typography variant="body1" data-testid="text_nft-name">
                  {token.metadata?.name}
                </Typography>
                <Typography variant="body2" data-testid="text_nft-desc">
                  {token.metadata?.description}
                </Typography>
                <Typography variant="body2" data-testid="text_nft-token-id">
                  {"#" + token.tokenID.toString().padStart(3, "0")}
                </Typography>
                <Typography variant="body2">ABC Chain</Typography>
              </Box>
            </Box>
            <Box display="flex" alignItems="center" sx={{ gap: "8px" }}>
              <SouthIcon></SouthIcon>
              <DetailDivinder />
            </Box>
            <Typography variant="subtitle1">To</Typography>
            <Box display="flex" sx={{ gap: "8px" }}>
              <IconBox>
                <EthIcon />
              </IconBox>
              <Box display="flex" flexDirection="column" sx={{ gap: "8px" }}>
                <Typography data-testid="text_chain-name" variant="body2">
                  Ethereum
                </Typography>
                <Typography
                  sx={{ wordBreak: "break-word" }}
                  data-testid="text_wallet-address"
                  variant="body1"
                >
                  {active ? account : "-"}
                </Typography>
              </Box>
            </Box>
          </ReviewCard>
        </Box>
        <FixedFooter>
          <MainButton
            data-testid="btn_ok"
            title="OK"
            handleAction={handleOnClickOk}
          />
          <Box height="16px" />
        </FixedFooter>
      </Box>
    </>
  );
};

export default BridgeSuccess;
