import React from "react";
import { styled } from "@mui/system";
import { TextField, Select, MenuItem } from "@mui/material";
import { paletteLight } from "theme";

export const ABCTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: paletteLight.grey.accent3,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: `1px solid ${paletteLight.grey.accent3}`,
    },
    input: {
      padding: "10px 12px",
      caretColor: paletteLight.grey.accent1,
      borderRadius: "16px",
    },

    height: "54px",
    width: "100%",
    borderRadius: "16px",
    background: paletteLight.grey.accent4,
  },
  width: "100%",
  marginBottom: "5px",
});

export const ABCSelect = styled(Select)({
  "& .MuiInputBase-input": {
    "&:focus": {
      background: paletteLight.grey.accent4,
    },
  },
  "& .MuiSvgIcon-root ": {
    right: "12px",
  },
  padding: "10px 12px",
  height: "54px",
  width: "100%",
  borderRadius: "16px",
  background: paletteLight.grey.accent4,
});

export const ABCMenuItem = styled(MenuItem)({
  "&.Mui-selected": {
    background: paletteLight.grey.accent3,
    "&:hover": {
      background: paletteLight.grey.accent3,
    },
    "&:focus": {
      background: paletteLight.grey.accent3,
    },
  },
});
