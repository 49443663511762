import { Box, Button, Typography, Modal } from "@mui/material";
import React from "react";
import { ReactComponent as ErrorIcon } from "assets/icons/error2.svg";

type PromptProps = {
  isVisible: boolean;
  onClose: () => void;
  title: string;
  subTitle: string;
  buttonText: string;
};

const Prompt = ({
  isVisible,
  onClose,
  title,
  subTitle,
  buttonText,
}: PromptProps) => {
  return (
    <Modal
      data-testid="modal_prompt"
      open={isVisible}
      onClose={onClose}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
      }}
    >
      <Box
        sx={[
          {
            display: "flex",
            flexDirection: "column",
            bgcolor: "background.paper",
            width: "100%",
            maxWidth: "340px",
            minHeight: "258px",
            m: "16px",
            justifyContent: "space-between",
            borderRadius: "16px",
            p: "24px",
            overflowY: "auto",
            maxHeight: "100%",
          },
          () => ({
            "&:focus-visible": {
              outline: "none",
            },
          }),
        ]}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            mb: "21px",
          }}
        >
          <ErrorIcon />
        </Box>
        <Typography
          id="lbl_titlePopup"
          variant="h6"
          sx={{
            mb: "8px",
          }}
          align="center"
        >
          {title}
        </Typography>
        <Typography
          id="lbl_contentPopup"
          variant="body2"
          sx={{
            mb: "16px",
          }}
          align="center"
        >
          {subTitle}
        </Typography>

        <Box
          sx={{
            width: "100%",
            mb: 1,
          }}
        >
          <Button
            id="btn_1Popup"
            data-testid="btn_1Popup"
            color="primary"
            variant="contained"
            sx={{
              borderRadius: 16,
              height: "50px",
              width: "100%",
            }}
            onClick={onClose}
          >
            <Typography variant="button">{buttonText}</Typography>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default Prompt;
